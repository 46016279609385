import {Link} from "react-router-dom";
import {PATHS, PATHS_API} from "../../../../../../../../enum";
import ThemeSwitcher from "../../../../ThemeSwitcher/ThemeSwitcher";
import {useUserStore} from "../../../../../../../../modules/shop/user/store";

const ContentDeposit = ({ setActive }) => {
    const user = useUserStore(store => store.user)

    return (
        <div style={{width:"100%", margin: "20px 0"}} className="d-flex flex-direction-column ai-center just-content-center">
            { user?.steamId ?
                <>
                    <Link
                        className="burger-content-btn"
                        to={PATHS.PROFILE + "/basket"}
                        onClick={() => setActive(false)}
                    >
                        Профиль
                    </Link>
                    <a style={{"textDecoration" : "none", margin: "20px 0"}} className='btn' href={PATHS_API.LOGOUT}>
                        Выйти с учётной записи
                    </a>
                </>
                :
                <a style={{"textDecoration" : "none", margin: "20px 0"}} className='btn' href={PATHS_API.LOGIN}>
                    Вход
                </a>
            }
            {/*<ThemeSwitcher />*/}
        </div>
    );
};

export default ContentDeposit;