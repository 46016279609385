import "./ShopPage.scss";
import {filterCategoryResult} from "./components/Categories/filterCategoryResult";
import {useEffect, useState} from "react";
import {PATHS, PATHS_API, SERVER_NAME} from "../../../enum";
import {filterServerResult} from "./components/Categories/filterServerResult";
import {useProductsStore} from "../../../modules/shop/products/store";
import {useCategoriesStore} from "../../../modules/shop/categories/store";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Products from "./components/Products/Products";
import Categories from "./components/Categories/Categories";

const ShopPage = () => {
    //Получения всех категорий
    const categories = useCategoriesStore(state => state.categories)
    const fetchCategories = useCategoriesStore(state => state.fetchCategories)
    const [activeCategory, setActiveCategory] = useState({_id: 0, name: "Все"})

    //Получение всех товаров
    const products = useProductsStore(state => state.products)
    const fetchProducts = useProductsStore(state => state.fetchProducts)

    useEffect(() => {
        fetchCategories()
        fetchProducts()
    }, [])

    //Фильтр по серверам
    const [activeServerHeader, setActiveServerHeader] = useState({id: 0, name: SERVER_NAME.ALL})
    const filterProductsServer = filterServerResult(activeServerHeader.name, products)

    //Фильтр по категориям
    const filterProductsCategory = filterCategoryResult(activeCategory, filterProductsServer)

    return (
        <>
            <div className="wrapper-content">
                <div className="content">
                    <Header />
                    <div className="containerContent">
                        <Categories
                            dataCategories={categories}
                            activeCategory={activeCategory}
                            setActiveCategory={setActiveCategory}
                            activeServerHeader={activeServerHeader}
                            setActiveServerHeader={setActiveServerHeader}
                        />
                    </div>
                </div>
                <Products dataProducts={filterProductsCategory} />
                <p style={{color: "#1e1f22"}}>7f010f3a6f36416cce2f7126ce9b803d95121bb7</p>
                <Footer />
            </div>
        </>
    );
};

export default ShopPage;