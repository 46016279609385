import "./Basket.scss";
import Alert from "../../../../shared/UIComponents/Alert";
import AlertSecondary from "../../../../shared/UIComponents/AlertSecondary/AlertSecondary";
import {useEffect, useState} from "react";
import {Purchase} from "./interfaces";
import NoItems from "./components/NoItems/NoItems";
import {getPurchases} from "./components/requests";
import ActivatedServices from "./components/ActivatedServices/ActivatedServices";
import BasketItems from "./components/BasketItems/BasketItems";

const Basket = () => {
    const [ purchasesServices, setPurchasesServices ] = useState<Purchase[]>([])
    const [ purchasesProducts, setPurchasesProducts ] = useState<Purchase[]>([])


    useEffect(() => {
        getPurchases(setPurchasesServices, setPurchasesProducts)
    }, [])

    return (
        <div className="basket-wrapper">
            <Alert text={"Чтобы забрать товар в игре, нажмите кнопку \"U\""}/>
            <AlertSecondary text={"Товары ниже можно вернуть либо один раз сменить сервер выдачи"} />
            <ActivatedServices purchasesServices={purchasesServices} />
            <BasketItems purchasesProducts={purchasesProducts} setPurchasesProducts={setPurchasesProducts} />
            <NoItems purchasesProducts={purchasesProducts} purchasesServices={purchasesServices} />
        </div>
    );
};

export default Basket;