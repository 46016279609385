import { Routes } from "./index";
import PrivateRoute from "./PrivateRoute";
import {PATHS} from "./utils/Paths.links";
import {Switch, Redirect} from "react-router-dom";
import {useEffect, useState} from "react";
import {useUserStore} from "../../modules/shop/user/store";
import Loader from "rsuite/Loader";

const AppRouter = () => {
    const [isLoading, setIsLoading] = useState(true); // Флаг загрузки данных
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const user = useUserStore((state) => state.user);       // Текущее состояние пользователя
    const fetchUser = useUserStore((state) => state.fetchUser); // Функция загрузки пользователя

    useEffect(() => {
        const initializeAuth = async () => {
            try {
                await fetchUser(); // Ждем загрузку данных пользователя с бэка
            } catch (error) {
                console.error("Ошибка при получении пользователя:", error);
            } finally {
                setTimeout(() => {
                    setIsLoading(false); // Завершаем состояние загрузки
                }, 500);

            }
        };

        initializeAuth();
    }, []);



    useEffect(() => {
        // Проверка актуального состояния пользователя после загрузки
        if (user?.steamId) {
            // localStorage.setItem("isAuth", "true");
            setIsAuthenticated(true);
        } else {
            // localStorage.removeItem("isAuth");
            setIsAuthenticated(false);
        }

    }, [user]);

    return (
        <>
            {
                isLoading
                    ? <Loader size="lg" backdrop />
                    : <Switch>
                        { Routes.map((route) =>
                            <PrivateRoute
                                {...route}
                                token={isAuthenticated}
                                key={route.path}
                            />
                        )}
                        <Redirect to={PATHS.SHOP} />
                    </Switch>
            }
        </>
    )
};

export default AppRouter;
