import InputAmount from "../../../../../../shared/Inputs/InputAmount";
import InputDefault from "../../../../../../shared/Inputs/InputDefault";
import {useEffect, useState} from "react";
import {useTransfersStore} from "../../../../../../../modules/profile/transfers/store";
import {getCurrentDateInSeconds} from "../../../../../../shared/functions/getRandomId";

const TransferBlock = ({fetchTransfers, isLoading, createTransfer, setDataForToaster, fetchUser }) => {
    const [amountForTransfer, setAmountForTransfer] = useState(100)
    const [steamIdForTransfer, setSteamIdForTransfer] = useState("")

    useEffect(() => {
        fetchTransfers()
    }, [isLoading])

    const transfer = () => {
        if (!amountForTransfer || !steamIdForTransfer) {
            setDataForToaster({
                message: "Ключевое поле не заполнено",
                type: "warning",
                id: getCurrentDateInSeconds()
            })
            return;
        }

        createTransfer(
            amountForTransfer,
            steamIdForTransfer,
            setDataForToaster,
            setAmountForTransfer,
            setSteamIdForTransfer,
            fetchUser
        )
    }

    return (
        <>
            <div className="wrapper-input-transfer">
                <InputAmount value={amountForTransfer} setValue={setAmountForTransfer} disablePrompt placeholder={"100"} />
                <InputDefault value={steamIdForTransfer} setValue={setSteamIdForTransfer} placeholder={"example - 76561198121789747"} />
                {/*<div className="clue-transfer">Как правильно перевести баланс?</div>*/}
            </div>
            <div className="wrapper-button-transfer">
                <button
                    disabled={isLoading}
                    onClick={() => transfer()}
                    className="button-buy"
                >
                    {isLoading ? "Ожидание ответа..." : "Отправить"}
                </button>
            </div>
        </>
    );
};

export default TransferBlock;