import "./Categories.scss";
import DropDownNav from "../../../../shared/DropDowns/DropDownNav";
import {useServersStore} from "../../../../../modules/shop/servers/store";

const Categories = (
    {
        dataCategories,
        activeCategory,
        setActiveCategory,
        activeServerHeader,
        setActiveServerHeader
    }) => {

    //Получение всех серверов для фильтра в header
    const servers = useServersStore(state=> state.servers)
    // const serversHeader = [{_id: 0, name: "Все сервера"}, ...servers]

    return (
        <div className="navigation">
            <div className="navigation-buttons">
                { dataCategories.map((category, idx) => {
                    return (
                        <button
                            key={idx}
                            onClick={() => setActiveCategory(category)}
                            className={`nav-tab ${activeCategory._id === category._id || activeCategory.name === category.name ? "active": ""}`}>
                            { category.name }
                        </button>
                    )})
                }
            </div>
            {/*<DropDownNav*/}
            {/*    data={serversHeader}*/}
            {/*    activeOption={activeServerHeader}*/}
            {/*    setActiveOption={setActiveServerHeader}*/}
            {/*    width={"150px"}*/}
            {/*/>*/}
        </div>
    );
};

export default Categories;