import "./Navbar.scss";
import logo2 from "/src/app/assets/logo-mini.png"
import logo from "/src/app/assets/logo.png";
import {Link, useLocation} from "react-router-dom";
import {useEffect} from "react";
import {useUserStore} from "../../../../../modules/shop/user/store";
import {PATHS, PATHS_API} from "../../../../../enum";
import ThemeSwitcher from "../ThemeSwitcher/ThemeSwitcher";
import {CHILD_PROFILE_PATHS} from "../../../ProfilePage/components/Sidebar/Sidebar";
import DepositWallet from "../../../../shared/UIComponents/DepositWallet/DepositWallet";
import NotificationBell from "./components/NotificationBell/NotificationBell";
import BurgerButton from "./components/BurgerButton/BurgerButton";

const Navbar = () => {
    const user = useUserStore(state => state.user)
    const fetchUser = useUserStore(state => state.fetchUser)
    const location = useLocation();

    useEffect(() => {
        fetchUser()
    }, [])

    const ff = () => localStorage.setItem('isAuth', 'true')

    return (
        <div className="wrapper-fake">
            <div className="wrapper">
                <nav className="navbar">
                    <Link className="navbar-brand" to={PATHS.SHOP}>
                        <img className="navbar-logo" src={logo} alt="logo"/>
                        <img className="navbar-logo-mini" src={logo2} alt="logo"/>
                    </Link>
                    <div className="wrapper-theme-switcher">
                        {/*<ThemeSwitcher />*/}
                        {/*<button onClick={ff}>set</button>*/}
                    </div>
                    {
                        location.pathname.startsWith(PATHS.PROFILE)
                            ?
                                <>
                                    {/*<NotificationBell />*/}
                                    <DepositWallet user={user} />
                                    <div className="profile-info">
                                        <img
                                            className="avatar-profile"
                                            src={user.avatarUrl}
                                            alt="avatar"
                                        />
                                        <div className="name-profile">
                                            { user.personaName }
                                        </div>
                                    </div>
                                    <BurgerButton />
                                </>
                            :
                            user?._id ?
                                <>
                                    {/*<NotificationBell />*/}
                                    <DepositWallet user={user} />
                                    <Link className='btn profile-btn' to={PATHS.PROFILE + CHILD_PROFILE_PATHS.BASKET}>
                                        Профиль
                                    </Link>
                                    <BurgerButton />
                                </>
                                :
                                <>
                                    <a style={{"textDecoration" : "none", marginLeft: "auto"}} className='btn profile-btn' href={PATHS_API.LOGIN}>
                                        Вход
                                    </a>
                                    <BurgerButton />
                                </>
                    }
                </nav>
            </div>
        </div>
    );
};

export default Navbar;