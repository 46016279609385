import { create } from 'zustand'
import axios from "axios";
import {PATHS_API} from "../../../enum";

type Transfer = {
    amount: number,
    balanceInfo: {
        after: number,
        before: number
    },
    date: string,
    recipient: string,
    sender: string,
    _id: string
}

type Store = {
    transfers: Transfer[]
    isLoading: boolean,
    fetchTransfers: any
    createTransfer: any
    totalItems: number
}

export const useTransfersStore = create<Store>((set) => ({
    transfers: [],
    isLoading: false,
    totalItems: 0,
    errors: [],
    fetchTransfers: async (activePage: number) => {
        const result = await axios.get(`${ PATHS_API.TRANSFERS }/self?page=${ activePage }&limit=6`, {
            withCredentials: true
        })
        set({transfers: result.data.transfers, totalItems: result.data.totalItems})

    },
    createTransfer: async (
        amountForTransfer: any,
        steamIdForTransfer: any,
        setToastProps: any,
        setAmountForTransfer: any,
        setSteamIdForTransfer: any,
        fetchUser: any
    ) => {
        if (steamIdForTransfer.length === 0) {
            return
        }

        set({isLoading: true})

        await axios.post( PATHS_API.TRANSFER, {
            "amount": +amountForTransfer,
            "recipient": steamIdForTransfer
        }, {
            withCredentials: true,
        })
        .then((data) => {
            setToastProps({ message: data.data.message, type: "success", id: data.data.id });
            fetchUser()
            setAmountForTransfer("")
            setSteamIdForTransfer("")
        })
        .catch(error => {
            const errorMessage = error.response?.data?.message || "Произошла ошибка";
            setToastProps({ message: errorMessage, type: "error", id: error.response?.data?.id });
        })
        .finally(() => {
            set({isLoading: false})
        });
    }
}))