import { create } from 'zustand'
import {IUser} from "../../../app/pages/ShopPage/components/Navbar/interface";
import axios from "axios";
import {PATHS_API} from "../../../enum";
import {useLocalStorage} from "../../../app/hooks/useLocalStorage";

type Store = {
    user: IUser,
    fetchUser: any
}

export const useUserStore = create<Store>((set) => ({
    user: [],
    isLoading: false,
    errors: [],
    // fetchUser: async () => {
    //     try {
    //         const response = await axios.get(PATHS_API.SELF, {
    //             withCredentials: true,
    //         });
    //         set({ user: response.data });
    //     } catch (error) {
    //         console.error("Ошибка при загрузке пользователя:", error);
    //     }
    // },
    fetchUser: async () => {
        //set({ isLoading: true });
        try {
            const response = await axios.get(PATHS_API.SELF, {
                withCredentials: true,
            });
            set({ user: response.data });
        } catch (error) {
            console.error("Ошибка загрузки пользователя:", error);
        } finally {
            //set({ isLoading: false });
        }
    },
}))
