import ProductCardProfile from "../../../../components/ProductCardProfile";

const BasketItems = ({ purchasesProducts, setPurchasesProducts }) => {
    const handleRemoveProduct = async (productId) => {
        const updatedProducts = purchasesProducts.filter(product => product._id !== productId);
        setPurchasesProducts(updatedProducts);
    };

    return (
        !!purchasesProducts.length &&
        <div className="basket-wrapper">
            <h4 className="title-in-child">Товары с корзины</h4>
            <div className="basket-wrapper">
                { purchasesProducts.map((product, idx) => {
                    return (
                        <ProductCardProfile
                            key={idx}
                            data={product}
                            onRemove={() => handleRemoveProduct(product._id)} // Передача функции удаления
                        />
                    )})
                }
            </div>
        </div>
    );
};

export default BasketItems;