import {useUserStore} from "../../../../../../../modules/shop/user/store";
import {formatDate, formatTime} from "../../../../../../shared/functions/formateDate";
import {useEffect, useState} from "react";
import {useTransfersStore} from "../../../../../../../modules/profile/transfers/store";
import Pagination from "../../../../../../shared/Pagination/Pagination";
import {usePaymentsStore} from "../../../../../../../modules/profile/payments/store";
import {Loader} from "rsuite";

const TransfersList = ({ transfers, isLoading }) => {
    const user = useUserStore(state => state.user)
    const fetchTransfers = useTransfersStore(state => state.fetchTransfers)
    const totalItems = useTransfersStore(state => state.totalItems)
    const [activePage, setActivePage] = useState(1);

    useEffect(() => {
        fetchTransfers(activePage)
    }, [activePage])


    return (
        <>
            <div className="d-flex payments-title just-content-space-between">
                <div className="date-transfer d-flex just-content-center">
                    Дата
                </div>
                <div className="d-flex just-content-center">
                    Steam ID
                </div>
                <div className="d-flex just-content-center">
                    Сумма
                </div>
            </div>
            <div className="wrapper-transfers-list">
                {
                    isLoading
                        ? <Loader size="md" backdrop />
                        : transfers.map((payment, idx) => {
                            return(
                                <div key={idx} className={`payment ${idx % 2 === 0 ? "dark" : "light"}`}>
                                    <div className="date-transfer">
                                        <div>
                                            <strong>
                                                { formatDate(payment.date) }
                                            </strong>
                                        </div>
                                        <div>
                                            { formatTime(payment.date) }
                                        </div>
                                    </div>
                                    <div><strong>{user.steamId === payment.sender ? payment.recipient : payment.sender}</strong></div>
                                    <div className="d-flex">
                                        <strong className="d-flex">
                                            { user.steamId === payment.recipient
                                                ? <div className="recipient-amount transfer-amount">+ { payment.amount } RUB</div>
                                                : <div className="transfer-amount">- { payment.amount } RUB</div>
                                            }
                                        </strong>
                                    </div>
                                </div>
                            )
                    })
                }
            </div>
            <div style={{marginTop: "auto"}}>
                <Pagination totalItems={totalItems} active={activePage} setActive={setActivePage}/>
            </div>
        </>
    );
};

export default TransfersList;