
const NoBonusCode = () => {
    return (
        <div className="no-payments">
            <h3>
                Бонус-код сюда! Используй его, пока мы не передумали! <span>😆</span>
            </h3>
            <p>
                Переодически мы выкладываем их в нашем Discord
            </p>
        </div>
    );
};

export default NoBonusCode;