import { FC, useEffect } from "react";
import useToaster from "rsuite/useToaster";
import Message from "rsuite/Message";
import * as React from "react";

export interface ToasterProps {
    message: string; // Текст уведомления
    type: "info" | "success" | "warning" | "error"; // Тип уведомления
    id: string
}

const Toaster: FC<ToasterProps> = React.memo(({ message, type, id }) => {
    const toaster = useToaster();

    useEffect(() => {
        toaster.push(
            <Message style={{zIndex: 21312312313}} showIcon type={type} closable>
                <strong>{message}</strong>
            </Message>,
            { placement: "bottomStart", duration: 4000 }
        );
    }, [message, type, toaster, id]); // useEffect с зависимостями для того, чтобы показать тост только при изменении message или type

    return null; // не нужно ничего рендерить в DOM, только вызывать showToaster
});

export default Toaster;
