import { create } from 'zustand'
import axios from "axios";
import {IDataProduct} from "../../../app/pages/ShopPage/components/Products/interfaces";
import {PATHS_API} from "../../../enum";

type Store = {
    products: IDataProduct[]
    fetchProducts: any
}

export const useProductsStore = create<Store>((set) => ({
    products: [],
    isLoading: false,
    errors: [],
    fetchProducts: async () => {
        const result = await axios.get( PATHS_API.PRODUCTS, {
            withCredentials: true
        })
        const sortedItems = result.data.sort((a, b) => b.priority - a.priority);
        set({products: sortedItems})
    }
}))