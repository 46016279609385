import {useUserStore} from "../../../../../modules/shop/user/store";
import {copyText} from "../../../../shared/functions/clickCopyText";
import {getCurrentDateInSeconds} from "../../../../shared/functions/getRandomId";
import Toaster from "../../../../shared/Toaster";
import {useState} from "react";
import {ToasterProps} from "../../../../shared/Toaster/Toaster";
import CustomIcon from "../../../../shared/SvgIcons/CopyIcon";

const AboutAccount = () => {
    const [toastProps, setToastProps] = useState<ToasterProps | null>(null);

    const user = useUserStore(store => store.user)

    const copyTextFunc = async (text: string) => {
        try {
            await copyText(text) // Ждём завершения операции копирования
            setToastProps({
                message: "Данные добавлены в буфер обмена",
                type: "success",
                id: getCurrentDateInSeconds()
            })
        } catch (error) {
            setToastProps({
                message: "Ошибка при копировании данных в буфер обмена",
                type: "error",
                id: getCurrentDateInSeconds()
            })
        }
    }

    return (
        <div onClick={() => copyTextFunc(user.steamId)} className="data-title-content">
            <div onClick={() => copyTextFunc(user.steamId)} className="data-title-content">
                Ваш SteamId - <strong style={{fontSize: "16px"}}>{ user.steamId }</strong>
            </div>
            <button onClick={() => copyTextFunc(user.steamId)} className="copy-button">
                <CustomIcon />
            </button>
            {toastProps && <Toaster message={toastProps.message} type={toastProps.type} id={toastProps.id} />}
        </div>
    );
};

export default AboutAccount;