import InputDefault from "../../../../shared/Inputs/InputDefault";
import InputAmount from "../../../../shared/Inputs/InputAmount";
import "./Transfer.scss";
import {useEffect, useState} from "react";
import Pagination from "../../../../shared/Pagination";
import {useTransfersStore} from "../../../../../modules/profile/transfers/store";
import Toaster from "../../../../shared/Toaster";
import {useUserStore} from "../../../../../modules/shop/user/store";
import 'rsuite/Button/styles/index.css';
import NoItems from "./components/NoItems/NoItems";
import TransferBlock from "./components/TrasferBlock/TransferBlock";
import TransfersList from "./components/TransfersList/TransfersList";
import {ToasterProps} from "../../../../shared/Toaster/Toaster";
import Loader from "rsuite/Loader";

const Transfer = () => {
    const transfers = useTransfersStore(state => state.transfers)
    const createTransfer = useTransfersStore(state => state.createTransfer)
    const fetchTransfers = useTransfersStore(state => state.fetchTransfers)
    const fetchUser = useUserStore(state => state.fetchUser)
    const isLoading = useTransfersStore((state) => state.isLoading);


    //Toaster
    const [toastProps, setToastProps] = useState<ToasterProps | null>(null);

    useEffect(() => {
        fetchTransfers()
    }, [])

    return (
        <>
            <TransferBlock
                createTransfer={createTransfer}
                fetchUser={fetchUser}
                setDataForToaster={setToastProps}
                fetchTransfers={fetchTransfers}
                isLoading={isLoading}
            />
            {
                transfers.length
                ? <TransfersList transfers={transfers} isLoading={isLoading} />
                : <NoItems />
            }
            {toastProps && <Toaster message={toastProps.message} type={toastProps.type} id={toastProps.id} />}
        </>
    );
};

export default Transfer;