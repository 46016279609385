import {Link} from "react-router-dom";
import {PATHS} from "../../../../../../../routers/utils/Paths.links";
import {dataForProfile} from "../../../../../../ProfilePage/components/Sidebar/Sidebar";
import {PATHS_API} from "../../../../../../../../enum";
import {useUserStore} from "../../../../../../../../modules/shop/user/store";

const ContentProfile = ({menuRef, setActive}) => {
    const user = useUserStore(store => store.user)

    return (
        <div className="content-profile">
            { user?.steamId && dataForProfile.map((item, idx ) =>
                    <Link
                        key={idx}
                        ref={menuRef}
                        className="burger-content-btn"
                        to={PATHS.PROFILE + item.to}
                        onClick={() => setActive(false)}
                    >
                        { item.text }
                    </Link>

            )}
            <div className="static-burger-btn">
                <Link
                    className="burger-content-btn"
                    to={PATHS.SHOP}
                    onClick={() => setActive(false)}
                >
                    Вернутся в магазин
                </Link>
            </div>
            { user?.steamId ?
                <div className="d-flex just-content-center">
                    <a
                        style={{"textDecoration" : "none", margin: "10px 0 40px", width: "200px", display: "flex", justifyContent: "center"}}
                        className='btn'
                        href={PATHS_API.LOGOUT}
                    >
                        Выйти с учётной записи
                    </a>
                </div>
                :
                <a style={{"textDecoration" : "none", margin: "20px 0", display: "flex", justifyContent: "center"}} className='btn' href={PATHS_API.LOGIN}>
                    Вход
                </a>

            }
        </div>
    );
};

export default ContentProfile;